import React from 'react';
import {
  Flex,
  Text,
  Heading,
  Tooltip,
  IconButton,
  Link,
  Button,
  ScrollArea,
} from '@radix-ui/themes';
import { Cross1Icon, ExternalLinkIcon } from '@radix-ui/react-icons';
import { toTitleCase } from './utils';
import FilePDFIcon from './assets/file-pdf-icon.svg';
import './MoreInfoPanel.css';

const SourceDocument = ({ document }) => {
  return (
    <Flex
      direction="column"
      px="5"
      pt="4"
      pb="5"
      style={{ borderBottom: '#e8e8e8 1px solid' }}
    >
      <Flex align="center" gap="3" mb="3">
        <img src={FilePDFIcon} alt="Document icon" style={{ marginLeft: -6 }} />
        <Flex direction="column" style={{ minWidth: 0 }}>
          <Tooltip content={document.file_name}>
            <Heading
              size="2"
              as="h4"
              truncate={true}
              style={{
                fontWeight: 600,
                cursor: 'default',
              }}
            >
              {document.file_name}
            </Heading>
          </Tooltip>
          <Text size="1" weight="medium" color="gray">
            {toTitleCase(document.file_type)}
          </Text>
        </Flex>
      </Flex>
      {document.wayfinding_text && (
        <Text size="2" mb="4">
          {document.wayfinding_text}
        </Text>
      )}
      <Link
        href={`${document.file_link}#page=${document.match_page_number}`}
        target="_blank"
        rel="noopener noreferrer"
        style={{ maxWidth: 88 }}
      >
        <Button size="1">
          Open file
          <ExternalLinkIcon />
        </Button>
      </Link>
    </Flex>
  );
};

const MoreInfoPanel = ({
  children,
  title,
  subtitle,
  sources,
  onClose,
  isOpen,
}) => {
  return (
    <Flex
      direction="column"
      className={`more-info-panel ${isOpen ? 'open' : ''}`}
    >
      <Flex
        pl="4"
        pr="6"
        align="center"
        justify="between"
        style={{
          paddingTop: 22,
          paddingBottom: 22,
          borderBottom: '#e8e8e8 1px solid',
        }}
      >
        <Flex direction="column" style={{ minWidth: 0 }}>
          <Heading as="h3" size="4">
            {title}
          </Heading>
          <Text
            size="1"
            truncate={true}
            title={subtitle}
            style={{
              color: '#999',
              fontWeight: 600,
            }}
          >
            {subtitle}
          </Text>
        </Flex>
        <IconButton variant="ghost" onClick={onClose} ml="4">
          <Cross1Icon color="gray" />
        </IconButton>
      </Flex>

      <Flex direction="column" style={{ height: 'calc(100% - 142px)' }}>
        {/* 142px is top nav height + more info header height */}
        <ScrollArea scrollbars="vertical">
          {children}
          {sources &&
            sources.map((document, index) => (
              <SourceDocument key={index} document={document} />
            ))}
        </ScrollArea>
      </Flex>
    </Flex>
  );
};

export default MoreInfoPanel;
