import React, { useState, useContext } from 'react';
import { Dialog, Flex, Button } from '@radix-ui/themes';
import { ProjectContext } from './ProjectContext';
import FilePicker from './FilePicker';

const UploadFilesDialog = ({ trigger }) => {
  const { uploadDocuments } = useContext(ProjectContext);
  const [boxFolderUrl, setBoxFolderUrl] = useState('');
  const [files, setFiles] = useState([]);

  const handleUploadFiles = async () => {
    const data = uploadDocuments(files);
  };

  return (
    <Dialog.Root>
      <Dialog.Trigger>{trigger}</Dialog.Trigger>
      <Dialog.Content style={{ maxWidth: 450 }}>
        <Dialog.Title>Upload files</Dialog.Title>
        <Dialog.Description size="2" mb="4">
          Lateral will automatically extract important project attributes and
          financial model inputs from each file you upload.
        </Dialog.Description>
        <Flex direction="column" gap="3">
          <FilePicker
            files={files}
            setFiles={setFiles}
            boxFolderUrl={boxFolderUrl}
            setBoxFolderUrl={setBoxFolderUrl}
          />
        </Flex>
        <Flex gap="3" mt="5" justify="end">
          <Dialog.Close>
            <Button variant="soft" color="gray">
              Cancel
            </Button>
          </Dialog.Close>
          <Dialog.Close>
            <Button onClick={handleUploadFiles}>Upload</Button>
          </Dialog.Close>
        </Flex>
      </Dialog.Content>
    </Dialog.Root>
  );
};

export default UploadFilesDialog;
