import React, { createContext } from 'react';
import { BASE_URL } from './App';

export const DiligenceContext = createContext();

export const DiligenceProvider = ({ children }) => {
  const getDiligenceResults = async (projectId) => {
    const response = await fetch(
      `${BASE_URL}/api/v1/projects/${projectId}/diligence-checklist`,
      {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
        },
        credentials: 'include',
      }
    );
    const data = await response.json();

    return data;
  };

  return (
    <DiligenceContext.Provider value={{ getDiligenceResults }}>
      {children}
    </DiligenceContext.Provider>
  );
};
