import React, { createContext, useState, useCallback, useEffect } from 'react';
import { BASE_URL } from './App';
import { getCurrentDate } from './utils';

export const DataRoomContext = createContext();

export const DataRoomProvider = ({ children }) => {
  const [dataRoomTemplates, setDataRoomTemplates] = useState([]);
  const [dataRooms, setDataRooms] = useState([]);

  const fetchDataRooms = useCallback(async () => {
    const response = await fetch(`${BASE_URL}/api/v1/data-rooms`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
      },
      credentials: 'include',
    });
    const data = await response.json();
    setDataRooms(data.data_rooms);
  }, []);

  const fetchDataRoomTemplates = useCallback(async () => {
    const response = await fetch(`${BASE_URL}/api/v1/data-rooms/templates`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
      },
      credentials: 'include',
    });
    const data = await response.json();
    setDataRoomTemplates(data.templates);
  }, []);

  const createDataRoom = async ({
    name,
    templateId,
    projectId,
    boxParentFolderId,
  }) => {
    try {
      const response = await fetch(
        `${BASE_URL}/api/v1/projects/${projectId}/data-rooms`,
        {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          credentials: 'include',
          body: JSON.stringify({
            data_room_name: name,
            template_id: templateId,
            box_parent_folder_id: boxParentFolderId,
          }),
        }
      );

      const newDataRoomData = await response.json();

      if (response.ok) {
        const newDataRoom = {
          id: newDataRoomData.data_room_id,
          name: name,
          template_id: templateId,
          project_id: projectId,
          box_parent_folder_id: boxParentFolderId,
          created_at: getCurrentDate(),
        };

        setDataRooms([...dataRooms, newDataRoom]);
      } else {
        console.error('Failed to create data room:', newDataRoomData.message);
      }
    } catch (error) {
      console.error('Error creating new data room:', error);
    }
  };

  useEffect(() => {
    fetchDataRooms();
  }, [fetchDataRooms]);

  useEffect(() => {
    fetchDataRoomTemplates();
  }, [fetchDataRoomTemplates]);

  return (
    <DataRoomContext.Provider
      value={{
        dataRoomTemplates,
        createDataRoom,
        dataRooms,
      }}
    >
      {children}
    </DataRoomContext.Provider>
  );
};
