import React, { useState, useContext, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import {
  Text,
  Heading,
  Flex,
  Popover,
  Button,
  Checkbox,
  ScrollArea,
} from '@radix-ui/themes';
import { PlusIcon } from '@radix-ui/react-icons';
import { ProjectContext } from './ProjectContext';
import CreateProjectDialog from './CreateProjectDialog';
import Icon from './Icon';
import './ProjectSelector.css';

const ProjectSelector = () => {
  const {
    activeProject,
    setActiveProject,
    setSelectedProjects,
    selectedProjects,
    projects,
  } = useContext(ProjectContext);
  const navigate = useNavigate();
  const [showAllProjects, setShowAllProjects] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const [isCreateProjectOpen, setIsCreateProjectOpen] = useState(false);

  useEffect(() => {
    if (selectedProjects.length === 0) {
      setSelectedProjects(projects);
    }
  }, [projects, selectedProjects, setSelectedProjects]);

  const deselectActiveProject = () => {
    setActiveProject(null);
    navigate('../../');
  };

  const deselectAllProjects = () => {
    setSelectedProjects(projects);
    setShowAllProjects(true);
  };

  const handleCreateProject = () => {
    setIsOpen(false);
    setIsCreateProjectOpen(true);
  };

  const toggleProject = (project) => {
    setSelectedProjects((prevSelectedProjects) => {
      // If there aren't any selected projects, stop showing all and select only the clicked project
      if (showAllProjects) {
        setShowAllProjects(false);

        if (activeProject && activeProject.id !== project.id) {
          deselectActiveProject();
        }

        return [project];
      }

      const isSelected = prevSelectedProjects.some((p) => p.id === project.id);
      if (isSelected) {
        // Check if the active project is being "unselected"
        if (activeProject && activeProject.id === project.id) {
          deselectActiveProject();
        }
        // Remove the project from SelectedProjects
        const updatedProjects = prevSelectedProjects.filter(
          (p) => p.id !== project.id
        );

        // If there are no projects left, show all projects
        if (updatedProjects.length === 0) {
          setShowAllProjects(true);
          return projects;
        }

        return updatedProjects;
      } else {
        // Add the project to SelectedProjects
        setShowAllProjects(false);
        return [...prevSelectedProjects, project];
      }
    });
  };

  // Selected projects should be ordered the same as in Left Nav (sorted by created_at)
  const sortedProjects = projects.sort(
    (a, b) => new Date(b.created_at) - new Date(a.created_at)
  );

  const hasProjects = sortedProjects.length > 0;

  return (
    <Popover.Root open={isOpen} onOpenChange={hasProjects ? setIsOpen : null}>
      <Popover.Trigger asChild>
        <Flex
          justify="between"
          align="center"
          className={`project-selector-trigger ${!hasProjects ? 'disabled' : ''}`}
        >
          <Flex direction="column">
            <Heading as="h3" size="4">
              Projects
            </Heading>
            {hasProjects && (
              <Text size="1" style={{ color: '#999', fontWeight: 600 }}>
                {selectedProjects.length} in sidebar
              </Text>
            )}
          </Flex>
          <Icon
            name="ChevronDownIcon"
            style={{ marginRight: 2, opacity: hasProjects ? 1 : 0.5 }}
          />
        </Flex>
      </Popover.Trigger>
      <Popover.Content
        side="right"
        sideOffset={-36}
        alignOffset={42}
        onOpenAutoFocus={(e) => e.preventDefault()}
        style={{ padding: 0, minWidth: 250, maxWidth: 320 }}
        className="project-selector-content"
      >
        <Flex
          justify="between"
          px="3"
          py="2"
          style={{ borderBottom: '1px solid #e8e8e8' }}
        >
          <Text size="1">Customize sidebar:</Text>
          <Button
            size="1"
            variant="ghost"
            disabled={showAllProjects}
            onClick={deselectAllProjects}
          >
            Reset
          </Button>
        </Flex>
        <ScrollArea scrollbars="vertical" style={{ maxHeight: 384 }}>
          <Flex direction="column" gap="1" px="1" py="1">
            {sortedProjects.map((project) => {
              const isSelected =
                !showAllProjects &&
                selectedProjects.some((p) => p.id === project.id);
              return (
                <Flex
                  key={project.id}
                  onClick={() => toggleProject(project)}
                  align="center"
                  gap="3"
                  pl="3"
                  pr="4"
                  py="2"
                  className="project-selector-item"
                >
                  <Checkbox size="1" checked={isSelected} readOnly />
                  <Text truncate={true} title={project.name} size="2">
                    {project.name}
                  </Text>
                </Flex>
              );
            })}
          </Flex>
        </ScrollArea>
        <Flex px="3" py="2" style={{ borderTop: '1px solid #e8e8e8' }}>
          <Button onClick={handleCreateProject} style={{ width: '100%' }}>
            <PlusIcon />
            Create project
          </Button>
        </Flex>
      </Popover.Content>
      <CreateProjectDialog
        open={isCreateProjectOpen}
        onOpenChange={() => setIsCreateProjectOpen(false)}
      />
    </Popover.Root>
  );
};

export default ProjectSelector;
