import React, { useContext, useEffect } from 'react';
import {
  BrowserRouter,
  Routes,
  Route,
  NavLink,
  Link,
  Navigate,
  useNavigate,
  useLocation,
  Outlet,
} from 'react-router-dom';
import { DropdownMenu, Text, Flex, ScrollArea } from '@radix-ui/themes';
import { useAuth } from './AuthContext';
import { DataRoomProvider } from './DataRoomContext';
import { DiligenceProvider } from './DiligenceContext';
import { ProjectContext, ProjectProvider } from './ProjectContext';
import { FAQProvider } from './FAQContext';
import AttributesOverview from './AttributesOverview';
import AttributesDetail from './AttributesDetail';
import AttributesPaywall from './AttributesPaywall';
import DataRoomsOverview from './DataRoomsOverview';
import DataRoomsPaywall from './DataRoomsPaywall';
import ProjectsOverview from './ProjectsOverview';
import ProjectDetail from './ProjectDetail';
import FAQOverview from './FAQOverview';
import FAQDetail from './FAQDetail';
import DiligenceOverview from './DiligenceOverview';
import DiligenceChecklist from './DiligenceChecklist';
import CreateAccountPage from './CreateAccountPage';
import LoginPage from './LoginPage';
import ProjectSelector from './ProjectSelector';

import Icon from './Icon';
import ProductIcon from './assets/product_logo.svg';
import { getProjectIconName, extractProjectIDFromURL } from './utils';
import './App.css';

export const BASE_URL = process.env.REACT_APP_BACKEND_BASE_URL;

const TopNav = () => {
  const { user, logout } = useAuth();
  const { activeProject } = useContext(ProjectContext);
  const navigate = useNavigate();

  const handleLogut = () => {
    logout();
  };

  return (
    <nav>
      <Flex justify="between" align="center" className="top-nav" px="5" py="3">
        <Flex gap="5">
          <img
            src={ProductIcon}
            alt="Lateral Energy"
            style={{ transform: 'translateY(2px)' }}
            onClick={() => navigate('/')}
          />
        </Flex>
        <Flex gap="5" align="center">
          <Flex gap="5">
            <NavLink
              to={
                activeProject
                  ? `/overview/projects/${activeProject.id}`
                  : '/overview'
              }
              className="top-nav-link"
            >
              Overview
            </NavLink>
            <NavLink
              to={activeProject ? `/faq/projects/${activeProject.id}` : '/faq'}
              className="top-nav-link"
            >
              FAQ
            </NavLink>
            {/* <NavLink
              to={
                activeProject
                  ? `/diligence/projects/${activeProject.id}`
                  : '/diligence'
              }
              className="top-nav-link"
            >
              Diligence
            </NavLink> */}
            <NavLink
              to={
                activeProject
                  ? `/attributes/projects/${activeProject.id}`
                  : '/attributes'
              }
              className="top-nav-link"
            >
              Attributes
            </NavLink>
            <NavLink
              to={
                activeProject
                  ? `/data-rooms/projects/${activeProject.id}`
                  : '/data-rooms'
              }
              className="top-nav-link"
            >
              Data rooms
            </NavLink>
          </Flex>
          <DropdownMenu.Root size="1">
            <DropdownMenu.Trigger>
              <Flex
                justify="center"
                align="center"
                className="user-avatar"
                style={{
                  background: '#D7CFF9',
                  cursor: 'default',
                  userSelect: 'none',
                }}
              >
                {user.email.charAt(0).toUpperCase()}
              </Flex>
            </DropdownMenu.Trigger>
            <DropdownMenu.Content variant="soft">
              <DropdownMenu.Label size="1">
                <Text
                  truncate={true}
                  title={user.email}
                  style={{ maxWidth: 200 }}
                >
                  {user.email}
                </Text>
              </DropdownMenu.Label>
              <DropdownMenu.Separator />
              <DropdownMenu.Item color="red" onClick={handleLogut}>
                Log out
              </DropdownMenu.Item>
            </DropdownMenu.Content>
          </DropdownMenu.Root>
        </Flex>
      </Flex>
    </nav>
  );
};

const LeftNav = () => {
  const { selectedProjects, activeProject } = useContext(ProjectContext);

  const getItemLink = (project) => {
    if (activeProject) {
      return project ? `../${project.id}` : '../../';
    }

    return project ? `projects/${project.id}` : '';
  };

  const LeftNavItem = ({ children, project, isActive, iconName }) => (
    <Link
      className={`left-nav-item ${isActive ? 'active' : ''}`}
      relative="path"
      to={getItemLink(project)}
    >
      <Icon
        name={iconName}
        fill={isActive ? 'purple' : 'black'}
        style={{ marginRight: 10 }}
      />
      <Text truncate={true} title={children}>
        {children}
      </Text>
      {isActive && <Icon name="CheckIcon" style={{ paddingLeft: 4 }} />}
    </Link>
  );

  // If the active project is set from query param (ie. not in the selected projects), add it to the list
  const projectsToDisplay =
    activeProject && !selectedProjects.some((p) => p.id === activeProject.id)
      ? [...selectedProjects, activeProject]
      : selectedProjects;

  // Projects should be ordered the same as in Project Selector (sorted by created_at)
  const sortedProjectsToDisplay = projectsToDisplay.sort(
    (a, b) => new Date(b.created_at) - new Date(a.created_at)
  );

  return (
    <Flex className="left-nav-container">
      <ProjectSelector />
      <ScrollArea
        scrollbars="vertical"
        style={{ height: 'calc(100% - 128px)' }} // 128px is the height of the top nav + project selector
      >
        <LeftNavItem iconName="AllIcon" isActive={!activeProject}>
          All projects
        </LeftNavItem>
        {sortedProjectsToDisplay.map((project) => (
          <LeftNavItem
            key={project.id}
            project={project}
            iconName={getProjectIconName(project.type)}
            isActive={project.id === activeProject?.id}
          >
            {project.name}
          </LeftNavItem>
        ))}
      </ScrollArea>
    </Flex>
  );
};

const Page = ({ children, title }) => {
  const { activeProject } = useContext(ProjectContext);

  useEffect(() => {
    document.title = `${activeProject ? `${activeProject.name} · ` : ''}${title}`;
  }, [activeProject, title]);

  return children || <Outlet />;
};

const Layout = () => (
  <div className="App">
    <TopNav />
    <div className="content-area">
      <LeftNav />
      <main className="main-content">
        <Outlet />
      </main>
    </div>
  </div>
);

const AuthenticatedRoutes = () => {
  const { projects, setActiveProject } = useContext(ProjectContext);
  const location = useLocation();
  const navigate = useNavigate();

  // Set the active project based on the URL
  useEffect(() => {
    const urlProjectID = extractProjectIDFromURL(location.pathname);
    let urlProject;

    if (urlProjectID) {
      urlProject = projects.find((p) => p.id === parseInt(urlProjectID));
    }

    setActiveProject(urlProject || null);
  }, [location.pathname, navigate, projects, setActiveProject]);

  return (
    <Routes>
      <Route element={<Layout />}>
        <Route path="/" element={<Navigate to="/overview" replace />} />
        <Route path="/overview" element={<Page title="Overview" />}>
          <Route index element={<ProjectsOverview />} />
          <Route path="projects/:projectId" element={<ProjectDetail />} />
        </Route>
        <Route path="/faq" element={<Page title="FAQ" />}>
          <Route index element={<FAQOverview />} />
          <Route path="projects/:projectId" element={<FAQDetail />} />
        </Route>
        <Route path="/diligence" element={<Page title="Diligence" />}>
          <Route index element={<DiligenceOverview />} />
          <Route path="projects/:projectId" element={<DiligenceChecklist />} />
        </Route>
        <Route path="/attributes" element={<Page title="Attributes" />}>
          <Route index element={<AttributesPaywall />} />
          <Route path="projects/:projectId" element={<AttributesPaywall />} />
        </Route>
        <Route path="/data-rooms" element={<Page title="Data rooms" />}>
          <Route index element={<DataRoomsPaywall />} />
          <Route path="projects/:projectId" element={<DataRoomsPaywall />} />
        </Route>
        <Route path="*" element={<Navigate to="/" replace />} />
      </Route>
    </Routes>
  );
};

const AuthenticatedApp = () => {
  return (
    <ProjectProvider>
      <FAQProvider>
        <DiligenceProvider>
          <DataRoomProvider>
            <AuthenticatedRoutes />
          </DataRoomProvider>
        </DiligenceProvider>
      </FAQProvider>
    </ProjectProvider>
  );
};

const PublicApp = () => (
  <Routes>
    <Route path="/login" element={<LoginPage />} />
    <Route path="/register" element={<CreateAccountPage />} />
    <Route path="*" element={<Navigate to="/login" />} />
  </Routes>
);

function App() {
  const { isAuthenticated } = useAuth();

  return (
    <BrowserRouter>
      {isAuthenticated ? <AuthenticatedApp /> : <PublicApp />}
    </BrowserRouter>
  );
}

export default App;
