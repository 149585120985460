import React from 'react';
import { Text, Flex, Spinner } from '@radix-ui/themes';
import { FileTextIcon } from '@radix-ui/react-icons';
import { pluralize } from './utils';

const SourcesToggle = ({
  sources = [],
  isLoading,
  isToggled,
  onClick,
  textColor,
  ...props
}) => {
  const hasSources = sources.length > 0;

  return (
    <Flex display="inline-flex" flexShrink="0" {...props}>
      <Flex
        direction="row"
        gap="2"
        align="center"
        justify="between"
        style={{
          border: '1px solid #e5e5e5',
          userSelect: 'none',
          padding: '8px 12px',
          borderRadius: '8px',
          height: 'fit-content',
        }}
        onClick={hasSources ? onClick : null}
      >
        <Flex gap="1" align="center">
          <FileTextIcon size="1" style={{ opacity: 0.5 }} />
        </Flex>
        <Text size="1" weight="medium" color={textColor}>
          {!isLoading ? (
            `${sources.length} ${pluralize('source', sources.length)}`
          ) : (
            <Flex gap="2" align="center">
              Loading sources... <Spinner size="1" />
            </Flex>
          )}
        </Text>
        {!isLoading && (
          <Text
            size="1"
            color="violet"
            weight="medium"
            ml="1"
            style={{ opacity: hasSources ? 1 : 0.6 }}
          >
            {isToggled ? 'Hide' : 'Show'}
          </Text>
        )}
      </Flex>
    </Flex>
  );
};

export default SourcesToggle;
