import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import {
  Box,
  Button,
  Checkbox,
  Flex,
  Heading,
  Link,
  Text,
  TextField,
} from '@radix-ui/themes';
import {
  IdCardIcon,
  EnvelopeClosedIcon,
  LockClosedIcon,
} from '@radix-ui/react-icons';
import { useAuth } from './AuthContext';
import LoginBG from './assets/login-bg.webp';
import './LoginPage.css';

const CreateAccountPage = () => {
  const { createUser } = useAuth();
  const navigate = useNavigate();

  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [inviteCode, setInviteCode] = useState('');
  const [isAgreed, setIsAgreed] = useState(false);

  const handleSubmit = async (e) => {
    e.preventDefault();
    const isSuccess = await createUser(email, password, inviteCode, isAgreed);
    if (isSuccess) {
      navigate('/');
    } else {
      alert('Failed to create account');
      setPassword('');
    }
  };

  const handleKeyDown = (e) => {
    if (e.key === 'Enter') {
      handleSubmit(e);
    }
  };

  return (
    <Flex
      className="login-container"
      direction="column"
      align="center"
      style={{ backgroundImage: `url(${LoginBG})` }}
    >
      <Flex className="login-logo" my="9">
        LATERAL ENERGY
      </Flex>
      <Flex
        className="login-box"
        direction="column"
        mt={{ initial: 0, xs: '16vh' }}
        mb="9"
        p="5"
      >
        <Flex direction="column" width={{ initial: '100%', xs: '280px' }}>
          <Heading mb="5" align="center" style={{ fontWeight: 600 }}>
            Create an account
          </Heading>
          <TextField.Root
            type="email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            onKeyDown={handleKeyDown}
            placeholder="Email"
            size="3"
            mb="4"
          >
            <TextField.Slot>
              <EnvelopeClosedIcon color="gray" height="16" width="16" />
            </TextField.Slot>
          </TextField.Root>
          <TextField.Root
            type="password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            onKeyDown={handleKeyDown}
            placeholder="Password"
            size="3"
            mb="4"
          >
            <TextField.Slot>
              <LockClosedIcon height="16" width="16" />
            </TextField.Slot>
          </TextField.Root>
          <TextField.Root
            type="text"
            value={inviteCode}
            onChange={(e) => setInviteCode(e.target.value)}
            onKeyDown={handleKeyDown}
            placeholder="Invite code"
            size="3"
            mb="4"
          >
            <TextField.Slot>
              <IdCardIcon height="16" width="16" />
            </TextField.Slot>
          </TextField.Root>
          <Text as="label" size="1" mb="4">
            <Flex gap="2">
              <Checkbox
                size="1"
                checked={isAgreed}
                onCheckedChange={setIsAgreed}
              />
              <Box>
                Agree to{' '}
                <Link
                  href="https://www.lateralenergy.com/terms"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Terms of Service
                </Link>{' '}
                and{' '}
                <Link
                  href="https://www.lateralenergy.com/privacy"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Privacy Policy
                </Link>
              </Box>
            </Flex>
          </Text>
          <Button
            size="3"
            mt="2"
            color="gray"
            highContrast
            onClick={handleSubmit}
          >
            Create account
          </Button>
        </Flex>
      </Flex>
    </Flex>
  );
};

export default CreateAccountPage;
