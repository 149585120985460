import React from 'react';
import * as Assets from './assets';

const Icon = ({ className, name, ...props }) => {
  const SvgIcon = Assets[name];

  if (!SvgIcon) {
    return null;
  }

  return <SvgIcon className={className} {...props} />;
};

export default Icon;
