import React, { useState, useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import {
  Dialog,
  Text,
  Select,
  Flex,
  Button,
  TextField,
} from '@radix-ui/themes';
import { ProjectContext } from './ProjectContext';
import { slugify } from './utils';
import { PROJECT_TYPES } from './utils/constants';

const initialFormValues = {
  name: '',
  assetType: '',
};

const CreateProjectDialog = ({ trigger, open, onOpenChange }) => {
  const { createProject, setActiveProject, projects } =
    useContext(ProjectContext);
  const navigate = useNavigate();
  const [formValues, setFormValues] = useState(initialFormValues);
  const [errors, setErrors] = useState({});

  const handleOpenChange = () => {
    setFormValues(initialFormValues);
    setErrors({});

    onOpenChange && onOpenChange();
  };

  const validateForm = () => {
    let isValid = true;
    const newErrors = {};

    if (!formValues.name.trim()) {
      isValid = false;
      newErrors.name = 'Name is required';
    } else {
      // Check if the slugified project name already exists
      const slugifiedName = slugify(formValues.name.trim());
      const existingProject = projects.find(
        (project) => slugify(project.name) === slugifiedName
      );
      if (existingProject) {
        isValid = false;
        newErrors.name = 'Name already in use';
      }
    }

    if (!formValues.assetType.trim()) {
      isValid = false;
      newErrors.assetType = 'Asset type is required';
    }

    setErrors(newErrors);
    return isValid;
  };

  const handleInputChange = (field) => (e) => {
    setFormValues((prevValues) => ({
      ...prevValues,
      [field]: e.target ? e.target.value : e,
    }));
    // Clear error message when user starts typing
    if (errors[field]) {
      setErrors((prevErrors) => ({ ...prevErrors, [field]: null }));
    }
  };

  const handleCreateProject = async () => {
    if (!validateForm()) return;

    const newProject = await createProject({
      project_name: formValues.name.trim(),
      project_type: formValues.assetType,
    });

    setActiveProject(newProject);
    navigate(`/overview/projects/${newProject.id}`);
    onOpenChange && onOpenChange();
  };

  return (
    <Dialog.Root open={open} onOpenChange={handleOpenChange}>
      {trigger && <Dialog.Trigger>{trigger}</Dialog.Trigger>}
      <Dialog.Content style={{ maxWidth: 360 }}>
        <Dialog.Title mb="4">Create project</Dialog.Title>
        <Flex direction="column" gap="4">
          <label>
            <Flex justify="between" align="center" mb="1">
              <Text as="div" size="2" weight="bold">
                Name
              </Text>
              {errors.name && (
                <Text color="red" size="1">
                  {errors.name}
                </Text>
              )}
            </Flex>
            <TextField.Root
              placeholder="My project name"
              value={formValues.name}
              maxLength="100"
              onChange={handleInputChange('name')}
              {...(errors.name && {
                color: 'red',
                style: { border: '2px solid #EC8E7B', boxShadow: 'none' },
              })}
            />
          </label>
          <label>
            <Flex justify="between" align="center" mb="1">
              <Text as="div" size="2" weight="bold">
                Asset type
              </Text>
              {errors.assetType && (
                <Text color="red" size="1">
                  {errors.assetType}
                </Text>
              )}
            </Flex>
            <Select.Root
              value={formValues.assetType}
              onValueChange={handleInputChange('assetType')}
            >
              <Select.Trigger
                placeholder="Select type"
                style={{ width: '100%' }}
                {...(errors.assetType && {
                  color: 'red',
                  style: {
                    border: '2px solid #EC8E7B',
                    boxShadow: 'none',
                    width: '100%',
                  },
                })}
              />
              <Select.Content position="popper" variant="soft">
                {Object.entries(PROJECT_TYPES).map(([key, value]) => (
                  <Select.Item key={key} value={key}>
                    {value}
                  </Select.Item>
                ))}
              </Select.Content>
            </Select.Root>
          </label>
        </Flex>
        <Flex gap="3" mt="5" justify="end">
          <Dialog.Close>
            <Button variant="soft" color="gray">
              Cancel
            </Button>
          </Dialog.Close>
          <Button onClick={handleCreateProject}>Create</Button>
        </Flex>
      </Dialog.Content>
    </Dialog.Root>
  );
};

export default CreateProjectDialog;
