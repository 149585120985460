import React, { useState } from 'react';
import {
  Text,
  Tooltip,
  Flex,
  Card,
  RadioGroup,
  TextField,
} from '@radix-ui/themes';
import BoxFolderInfo from './BoxFolderInfo';
import './FilePicker.css';

const FilePicker = ({ files, setFiles, boxFolderUrl, setBoxFolderUrl }) => {
  const [uploadType, setUploadType] = useState('Manual');

  const handleToggleUploadType = (type) => {
    setBoxFolderUrl('');
    setUploadType(type);
  };

  return (
    <Card>
      <Flex direction="row" gap="3" mb="3" align="center">
        <Text as="label" size="2" weight="bold">
          Source:
        </Text>
        <RadioGroup.Root
          defaultValue="From computer"
          size="2"
          onValueChange={(value) => handleToggleUploadType(value)}
        >
          <Flex gap="4">
            <Text as="label" size="2">
              <Flex gap="2">
                <RadioGroup.Item value="From computer" />
                From computer
              </Flex>
            </Text>
            <Tooltip content="Box upload requires an Enterprise plan">
              <Text as="label" size="2" style={{ opacity: 0.5 }}>
                <Flex gap="2">
                  <RadioGroup.Item value="Box" disabled={true} />
                  Box folder
                </Flex>
              </Text>
            </Tooltip>
          </Flex>
        </RadioGroup.Root>
      </Flex>
      {uploadType === 'Box' ? (
        <Flex direction="column">
          <TextField.Root
            size="2"
            placeholder="Enter Box folder URL"
            onChange={(e) => setBoxFolderUrl(e.target.value)}
          />
          {boxFolderUrl && <BoxFolderInfo folderUrl={boxFolderUrl} />}
        </Flex>
      ) : (
        <input
          type="file"
          id="files-to-upload"
          multiple
          style={{
            padding: '10px',
            width: '94%',
            background: '#f9f9f9',
            border: '1px solid #d8d8d8',
            borderRadius: 4,
          }}
          onChange={(e) => setFiles(e.target.files)}
        />
      )}
    </Card>
  );
};

export default FilePicker;
