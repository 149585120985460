import { useState, useEffect } from 'react';

export const usePreventRefreshOnUpload = () => {
  const [isUploading, setIsUploading] = useState(false);

  useEffect(() => {
    const handleBeforeUnload = (event) => {
      if (isUploading) {
        event.preventDefault();
        event.returnValue = '';
      }
    };

    window.addEventListener('beforeunload', handleBeforeUnload);

    return () => {
      window.removeEventListener('beforeunload', handleBeforeUnload);
    };
  }, [isUploading]);

  return { isUploading, setIsUploading };
};
