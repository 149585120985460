import React, { useState, useContext } from 'react';
import {
  DropdownMenu,
  Text,
  Select,
  Flex,
  Button,
  Spinner,
  TextField,
  IconButton,
} from '@radix-ui/themes';
import { CaretDownIcon } from '@radix-ui/react-icons';
import { useInterval } from './hooks/useInterval';
import { ProjectContext } from './ProjectContext';
import { FAQContext } from './FAQContext';
import SourcesToggle from './SourcesToggle';
import Icon from './Icon';
import LateralAI from './assets/lateral-ai.svg';

const PublishToFAQDropdown = ({ onEditClick, onPublishClick }) => (
  <DropdownMenu.Root>
    <DropdownMenu.Trigger>
      <Button size="1">
        Publish to FAQ <CaretDownIcon />
      </Button>
    </DropdownMenu.Trigger>
    <DropdownMenu.Content variant="soft">
      <DropdownMenu.Item onClick={onEditClick}>Edit</DropdownMenu.Item>
      <DropdownMenu.Item onClick={onPublishClick}>
        Publish now
      </DropdownMenu.Item>
    </DropdownMenu.Content>
  </DropdownMenu.Root>
);

const initialFormValues = {
  question: '',
  fileId: '',
};

const AskLateralAI = ({
  faq,
  onViewSourcesClick,
  isViewingSources,
  onPublish,
}) => {
  const {
    createProjectQuestion,
    streamProjectQuestionAnswer,
    getProjectQuestion,
    publishToFAQ,
  } = useContext(FAQContext);
  const { activeProject } = useContext(ProjectContext);
  const [formValues, setFormValues] = useState(initialFormValues);
  const [questionAnswer, setQuestionAnswer] = useState(null);

  // Poll for answer sources every 5 seconds if it's finished streaming
  useInterval(
    () => fetchAnswerSources(),
    questionAnswer &&
      !questionAnswer.isStreaming &&
      questionAnswer.sources.length === 0
      ? 5000
      : null
  );

  const fetchAnswerSources = async () => {
    const { sources } = await getProjectQuestion(
      activeProject.id,
      questionAnswer.questionId
    );

    if (sources.length > 0) {
      setQuestionAnswer((prevAnswer) => ({
        ...prevAnswer,
        sources,
      }));
    }
  };

  const handleInputChange = (field) => (e) => {
    setFormValues((prevValues) => ({
      ...prevValues,
      [field]: e.target ? e.target.value : e,
    }));
  };

  const handleAskQuestion = async () => {
    if (!formValues.question.trim()) return;

    const { question_id: questionId } = await createProjectQuestion(
      activeProject.id,
      formValues.question
    );

    setFormValues(initialFormValues);
    setQuestionAnswer({
      questionId,
      questionText: formValues.question,
      answerText: '',
      sources: [],
      isStreaming: true,
    });

    const response = await streamProjectQuestionAnswer(
      activeProject.id,
      questionId
    );

    const reader = response.body.getReader();
    let chunks = '';

    while (true) {
      const { done, value } = await reader.read();
      if (done) break;
      chunks += new TextDecoder().decode(value);
      setQuestionAnswer((prevAnswer) => ({
        ...prevAnswer,
        answerText: chunks,
      }));
    }

    setQuestionAnswer((prevAnswer) => ({
      ...prevAnswer,
      isStreaming: false,
    }));
  };

  const handleKeyDown = (e) => {
    if (e.key === 'Enter') {
      handleAskQuestion();
    }
  };

  const handlePublishToFAQ = async () => {
    await publishToFAQ(activeProject.id, faq.id, questionAnswer.questionId);

    setQuestionAnswer(null);
    onPublish();
  };

  return (
    <Flex
      direction="column"
      mb="5"
      style={{
        background: 'white',
        border: '1px solid #e5e5e5',
        borderRadius: 12,
        overflow: 'hidden',
      }}
    >
      {!questionAnswer ? (
        <Flex
          direction={{ initial: 'column', sm: 'row' }}
          align="center"
          px={{ initial: '5', sm: '5' }}
          py={{ initial: '5', sm: '6' }}
          gap={{ initial: '5', sm: '4' }}
        >
          <Flex
            minWidth="136px"
            align="center"
            justify="center"
            mt={{ initial: '3', sm: '0' }}
          >
            <img
              src={LateralAI}
              alt="Lateral AI"
              style={{ width: 66, marginTop: -15, marginRight: -17 }} // recenter bc of new message icon
            />
          </Flex>
          <Flex direction="column" gap="2" style={{ maxWidth: 500 }}>
            <Text size="2" weight="bold">
              Have a question? Ask Lateral AI
            </Text>
            <Text size="2">
              Ask any question about this project and Lateral AI will use its
              files to provide answers immediately. Share insights by publishing
              them to the FAQ.
            </Text>
          </Flex>
        </Flex>
      ) : (
        <Flex direction="column" p="5" gap="4" width="100%">
          <Flex justify="between" align="center">
            <Text size="1" weight="bold" style={{ opacity: 0.5 }}>
              Lateral AI's answer
            </Text>
            {!questionAnswer.isStreaming && (
              <Flex gap="3">
                {/* <PublishToFAQDropdown onPublishClick={handlePublishToFAQ} /> */}
                <Button size="1" onClick={handlePublishToFAQ}>
                  Publish to FAQ
                </Button>
              </Flex>
            )}
          </Flex>
          <Flex direction="column" gap="3">
            <Text size="2" weight="bold">
              {questionAnswer.questionText}
            </Text>
            <Text size="2" style={{ whiteSpace: 'pre-wrap' }}>
              {questionAnswer.answerText}
            </Text>
            {!questionAnswer.isStreaming && (
              <SourcesToggle
                mt="2"
                textColor="gray"
                sources={questionAnswer.sources}
                isLoading={questionAnswer.sources.length === 0}
                isToggled={isViewingSources}
                onClick={() =>
                  onViewSourcesClick(
                    // TODO: clean this up
                    !isViewingSources
                      ? {
                          ...questionAnswer,
                          isUnpublished: true,
                          question: questionAnswer.questionText,
                        }
                      : null
                  )
                }
              />
            )}
          </Flex>
        </Flex>
      )}
      <Flex
        gap="4"
        p="4"
        style={{ background: '#F9F9FB', borderTop: '1px solid #e5e5e5' }}
      >
        <TextField.Root
          size="3"
          placeholder={
            !questionAnswer
              ? 'Ask Lateral AI about this project...'
              : questionAnswer.isStreaming
                ? 'Lateral AI is answering...'
                : 'Ask another question...'
          }
          disabled={questionAnswer && questionAnswer.isStreaming}
          value={formValues.question}
          onChange={handleInputChange('question')}
          onKeyDown={handleKeyDown}
          style={{ fontSize: 14, borderRadius: 8, width: '100%' }}
        >
          <TextField.Slot side="right">
            <IconButton
              size="1"
              variant="ghost"
              disabled={!formValues.question.trim()}
              onClick={handleAskQuestion}
            >
              {questionAnswer && questionAnswer.isStreaming ? (
                <Spinner />
              ) : (
                <Icon name="CircleArrowUpIcon" width="20" height="20" />
              )}
            </IconButton>
          </TextField.Slot>
        </TextField.Root>
        {/* <Flex style={{ width: '100%', maxWidth: 144 }}>
          <Select.Root
            size="3"
            disabled={questionAnswer && questionAnswer.isStreaming}
            value="all-files"
            onValueChange={handleInputChange('fileId')}
          >
            <Select.Trigger
              placeholder="Select file"
              style={{
                width: '100%',
                fontSize: 14,
                borderRadius: 8,
                background: '#F9F9FB',
              }}
            />
            <Select.Content position="popper" variant="soft">
              <Select.Item value="all-files" style={{ fontSize: 14 }}>
                All files
              </Select.Item>
            </Select.Content>
          </Select.Root>
        </Flex> */}
      </Flex>
    </Flex>
  );
};

export default AskLateralAI;
