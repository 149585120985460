import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Link, Heading, TextField, Flex, Button } from '@radix-ui/themes';
import { EnvelopeClosedIcon, LockClosedIcon } from '@radix-ui/react-icons';
import { useAuth } from './AuthContext';
import LoginBG from './assets/login-bg.webp';
import './LoginPage.css';

const LoginPage = () => {
  const { login } = useAuth();
  const navigate = useNavigate();

  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');

  const handleSubmit = async (e) => {
    e.preventDefault();
    const isSuccess = await login(email, password);
    if (isSuccess) {
      navigate('/');
    } else {
      alert('Login failed');
      setPassword('');
    }
  };

  const handleKeyDown = (e) => {
    if (e.key === 'Enter') {
      handleSubmit(e);
    }
  };

  return (
    <Flex
      className="login-container"
      direction="column"
      align="center"
      style={{ backgroundImage: `url(${LoginBG})` }}
    >
      <Flex className="login-logo" my="9">
        LATERAL ENERGY
      </Flex>
      <Flex
        className="login-box"
        direction="column"
        mt={{ initial: 0, xs: '16vh' }}
        mb="9"
        p="5"
      >
        <Flex direction="column" width={{ initial: '100%', xs: '280px' }}>
          <Heading mb="5" align="center" style={{ fontWeight: 600 }}>
            Sign in to your account
          </Heading>
          <TextField.Root
            type="email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            onKeyDown={handleKeyDown}
            placeholder="Email"
            size="3"
            mb="4"
          >
            <TextField.Slot>
              <EnvelopeClosedIcon color="gray" height="16" width="16" />
            </TextField.Slot>
          </TextField.Root>
          <TextField.Root
            type="password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            onKeyDown={handleKeyDown}
            placeholder="Password"
            size="3"
            mb="2"
          >
            <TextField.Slot>
              <LockClosedIcon height="16" width="16" />
            </TextField.Slot>
          </TextField.Root>
          <Link size="2" style={{ color: '#999' }} mb="5" href="#">
            Forgot password?
          </Link>
          <Button size="3" color="gray" highContrast onClick={handleSubmit}>
            Sign in
          </Button>
        </Flex>
      </Flex>
    </Flex>
  );
};

export default LoginPage;
