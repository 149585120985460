import React from 'react';
import { Text, Heading, Flex, Card, Button } from '@radix-ui/themes';
import BGImage from './assets/data-rooms-paywall.png';
import './DataRoomsPaywall.css';

const DataRoomsPaywall = () => {
  return (
    <Flex position="relative" className="data-rooms-paywall">
      <img
        src={BGImage}
        alt="Data rooms paywall"
        className="paywall-bg-image"
        draggable="false"
      />
      <Flex className="paywall-content">
        <Card
          size="3"
          style={{
            width: 330,
          }}
        >
          <Heading size="5" weight="bold" width="100%" align="center">
            Enterprise plan required
          </Heading>
          <Text as="p" size="2" mt="3" mb="5" width="100%" align="center">
            Lateral will automatically organize your project files into
            handoff-ready data rooms.
          </Text>
          <a href="mailto:info@lateralenergy.com">
            <Button size="2" style={{ width: '100%' }}>
              Request more info
            </Button>
          </a>
        </Card>
      </Flex>
    </Flex>
  );
};

export default DataRoomsPaywall;
