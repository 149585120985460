import React from 'react';
import { Avatar, Text, Flex, Heading } from '@radix-ui/themes';
import { ThickArrowLeftIcon } from '@radix-ui/react-icons';

const FAQOverview = () => {
  return (
    <Flex
      direction="column"
      align="center"
      style={{ maxWidth: 480, margin: '8rem auto 0' }}
    >
      <Avatar
        mb="5"
        color="gray"
        size="4"
        fallback={<ThickArrowLeftIcon height={24} width={24} />}
      />
      <Heading as="h2" size="5" mb="3">
        Select a project
      </Heading>
      <Text align="center" mb="4">
        Have questions about a project? Get precise, instant answers from
        Lateral AI. Select a project from the list on the left to begin.
      </Text>
    </Flex>
  );
};

export default FAQOverview;
