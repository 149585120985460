import React, { useEffect, useState } from 'react';
import { Avatar, Spinner, Text, Flex } from '@radix-ui/themes';
import Icon from './Icon';
import { BASE_URL } from './App';
import { isValidUrl } from './utils';

const BoxFolderInfo = ({ folderUrl }) => {
  const [folderInfo, setFolderInfo] = useState(null);
  const [error, setError] = useState(null);
  const isLoading = !folderInfo && !error;

  useEffect(() => {
    const fetchFolderInfo = async () => {
      setFolderInfo(null);
      setError(null);

      const url = folderUrl.trim();
      if (!url) return;

      if (!isValidUrl(url)) {
        return setError('Please enter a valid URL');
      }

      try {
        const response = await fetch(`${BASE_URL}/api/v1/box-folder-info`, {
          method: 'POST',
          credentials: 'include',
          headers: { 'Content-Type': 'application/json' },
          body: JSON.stringify({ box_url: url }),
        });

        if (!response.ok) {
          throw new Error(
            'Folder not found. Please check the URL and try again.'
          );
        }

        setFolderInfo(await response.json());
      } catch (error) {
        console.error('Error fetching Box folder info:', error);
        setError(error.message);
      }
    };

    fetchFolderInfo();
  }, [folderUrl]);

  return (
    <Flex mt="3">
      {isLoading && (
        <Flex align="center" gap="2">
          <Spinner size="1" />
          <Text size="1" color="gray">
            Loading folder info...
          </Text>
        </Flex>
      )}
      {error && (
        <Text size="1" color="red">
          {error}
        </Text>
      )}
      {folderInfo && (
        <Flex
          p="3"
          style={{
            width: '100%',
            background: '#f9f9f9',
            border: '1px solid #d8d8d8',
            borderRadius: 8,
          }}
        >
          <Flex align="center" gap="3">
            <Avatar
              size="3"
              radius="full"
              color="gray"
              style={{ opacity: 0.7 }}
              fallback={<Icon name="FolderIcon" width="24" height="24" />}
            />
            <Flex direction="column">
              <Text size="2" weight="bold">
                {folderInfo.name}
              </Text>
              <Text size="1" style={{ color: '#999', fontWeight: 600 }}>
                {folderInfo.num_files} files
              </Text>
            </Flex>
          </Flex>
        </Flex>
      )}
    </Flex>
  );
};

export default BoxFolderInfo;
