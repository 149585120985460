import React, { useContext, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { Text, Heading, Flex, Table, Badge, Button } from '@radix-ui/themes';
import { PlusIcon, TriangleUpIcon } from '@radix-ui/react-icons';
import { ProjectContext } from './ProjectContext';
import CreateProjectDialog from './CreateProjectDialog';
import Icon from './Icon';
import { getProjectIconName, prettifyDate } from './utils';
import { PROJECT_TYPES } from './utils/constants';
import './ProjectsOverview.css';

const ProjectsTable = ({ projects }) => {
  const navigate = useNavigate();

  return (
    <Table.Root variant="surface">
      <Table.Header>
        <Table.Row>
          <Table.ColumnHeaderCell width="20%">Type</Table.ColumnHeaderCell>
          <Table.ColumnHeaderCell width="65%">Name</Table.ColumnHeaderCell>
          <Table.ColumnHeaderCell width="15%">
            <Flex align="center" gap="1">
              Created
              <TriangleUpIcon style={{ color: '#bbb' }} />
            </Flex>
          </Table.ColumnHeaderCell>
        </Table.Row>
      </Table.Header>

      <Table.Body>
        {projects.map((project, index) => (
          <Table.Row
            key={index}
            className="projects-overview-row"
            onClick={() => navigate(`projects/${project.id}`)}
          >
            <Table.Cell maxWidth="96px">
              <Badge
                color="gray"
                variant="soft"
                size="1"
                style={{ maxWidth: '100%' }}
              >
                <Icon
                  name={getProjectIconName(project.type)}
                  width="12"
                  height="12"
                  style={{ marginRight: 2 }}
                />
                <Text truncate={true} title={PROJECT_TYPES[project.type]}>
                  {PROJECT_TYPES[project.type]}
                </Text>
              </Badge>
            </Table.Cell>
            <Table.Cell maxWidth="144px">
              <Flex>
                <Text truncate={true} title={project.name}>
                  {project.name}
                </Text>
              </Flex>
            </Table.Cell>
            <Table.Cell>
              <Text wrap="nowrap">{prettifyDate(project.created_at)}</Text>
            </Table.Cell>
          </Table.Row>
        ))}
      </Table.Body>
    </Table.Root>
  );
};

const ProjectsOverview = () => {
  const { selectedProjects, fetchProjects } = useContext(ProjectContext);

  useEffect(() => {
    fetchProjects();
  }, [fetchProjects]);

  if (selectedProjects.length === 0) {
    return (
      <Flex
        direction="column"
        align="center"
        style={{ maxWidth: 480, margin: '8rem auto 0' }}
      >
        <Heading as="h2" size="5" mb="3">
          Welcome to Lateral Energy
        </Heading>
        <Text align="center" mb="4">
          Get started by creating your first project. Once that's done, upload
          your files to start asking Lateral AI questions, and more.
        </Text>
        <CreateProjectDialog
          trigger={
            <Button>
              <PlusIcon />
              Create project
            </Button>
          }
        />
      </Flex>
    );
  }

  return (
    <Flex direction="column">
      <Flex mb="5" justify="between" align="center">
        <Heading size="7">All projects</Heading>
        <CreateProjectDialog
          trigger={
            <Button>
              <PlusIcon />
              Create project
            </Button>
          }
        />
      </Flex>
      <ProjectsTable projects={selectedProjects} />
    </Flex>
  );
};

export default ProjectsOverview;
