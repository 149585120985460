import React, { useContext, useEffect, useState } from 'react';
import { Badge, Box, Button, Flex, Heading, Text } from '@radix-ui/themes';
import { ChevronDownIcon } from '@radix-ui/react-icons';
import { PlayIcon, ReloadIcon } from '@radix-ui/react-icons';
import * as Accordion from '@radix-ui/react-accordion';
import { ProjectContext } from './ProjectContext';
import { DiligenceContext } from './DiligenceContext';
import MoreInfoPanel from './MoreInfoPanel';
import { prettifyDate } from './utils';
import SourcesToggle from './SourcesToggle';
import './DiligenceChecklist.css';
import { MORE_INFO_PANEL_WIDTH } from './utils/constants';

const DiligenceQuestionAccordion = ({
  diligenceResults,
  selectedQuestion,
  setSelectedQuestion,
}) => {
  const evaluationStatusToColor = (status) => {
    switch (status) {
      case 'passed':
        return 'green';
      case 'uncertain':
        return 'yellow';
      case 'failed':
        return 'red';
    }
  };

  const evaluationStatusToBadge = (status) => {
    switch (status) {
      case 'passed':
        return <Badge color={evaluationStatusToColor(status)}>PASSED</Badge>;
      case 'uncertain':
        return <Badge color={evaluationStatusToColor(status)}>UNCERTAIN</Badge>;
      case 'failed':
        return <Badge color={evaluationStatusToColor(status)}>FAILED</Badge>;
    }
  };

  return (
    <Box mt="4">
      <Heading size="5" my="2">
        Acceptance criteria
      </Heading>
      <Accordion.Root
        type="multiple"
        className="AccordionRoot"
        collapsible="true"
      >
        {diligenceResults.map((result, index) => (
          <Accordion.Item
            className="AccordionItem"
            key={index}
            value={result.id}
            py="5"
          >
            <Accordion.Header className="AccordionHeader">
              <Accordion.Trigger className="AccordionTrigger">
                <Flex direction="row" justify="between" align="center">
                  <Text weight="medium" size="2">
                    {result.question_text}
                  </Text>
                  <Flex align="center" gap="5" ml="5">
                    {evaluationStatusToBadge(result.evaluation_status)}
                    <ChevronDownIcon
                      className="AccordionChevron"
                      color="gray"
                    />
                  </Flex>
                </Flex>
              </Accordion.Trigger>
            </Accordion.Header>
            <Accordion.Content className="AccordionContent">
              <Flex
                direction={{ initial: 'column', md: 'row' }}
                justify="between"
                gap="2"
                className="AccordionContentText"
              >
                <Flex direction="column" style={{ maxWidth: '52em' }}>
                  <Text size="2" mb="3" style={{ fontWeight: 600 }}>
                    {result.answer_text}
                  </Text>
                  <Text size="2">{result.rationale}</Text>
                </Flex>
                <SourcesToggle
                  sources={result?.sources}
                  isToggled={selectedQuestion === result}
                  onClick={() =>
                    setSelectedQuestion(
                      selectedQuestion === result ? null : result
                    )
                  }
                  ml={{ initial: '0', md: '6' }}
                  mt={{ initial: '3', md: '0' }}
                />
              </Flex>
            </Accordion.Content>
          </Accordion.Item>
        ))}
      </Accordion.Root>
    </Box>
  );
};

const DiligenceChecklist = () => {
  const { getDiligenceResults } = useContext(DiligenceContext);
  const { activeProject } = useContext(ProjectContext);
  const [diligenceResults, setDiligenceResults] = useState([]);
  const [summary, setSummary] = useState('');
  const [selectedQuestion, setSelectedQuestion] = useState(null);

  useEffect(() => {
    activeProject &&
      getDiligenceResults(activeProject.id).then((results) => {
        setDiligenceResults(results.diligence_checklist);
        setSummary(results.summary);
      });
  }, [activeProject, getDiligenceResults]);

  if (diligenceResults.length === 0) {
    return (
      <Flex
        direction="column"
        align="center"
        style={{ maxWidth: 520, margin: '8rem auto 0' }}
      >
        <Heading size="5" mb="3">
          Start project due diligence
        </Heading>
        <Text align="center" mb="4">
          Once you've uploaded your project's files, click below and we'll run a
          series of diligence checks to evaluate the most pressing criteria for
          you.
        </Text>
        <Button>
          <PlayIcon />
          Run diligence checklist
        </Button>
      </Flex>
    );
  }

  const lastRan = prettifyDate(diligenceResults[0].created_at);

  return (
    <Flex
      direction="column"
      style={selectedQuestion && { marginRight: MORE_INFO_PANEL_WIDTH }}
    >
      <Box
        className="diligence-checklist-header"
        style={{
          borderRadius: '8px',
          border: '1px solid #e5e5e5',
          backgroundColor: 'white',
          overflow: 'hidden',
        }}
      >
        <Flex p="5" direction="row" justify="between">
          <Flex direction="column" gap="1">
            <Heading size="6">Diligence checklist</Heading>
            <Text size="2" color="gray">
              Ran {lastRan}
            </Text>
          </Flex>
          <Button color="primary" size="2" variant="solid">
            <ReloadIcon />
            Run again
          </Button>
        </Flex>
        <Box
          p="5"
          style={{
            backgroundColor: '#FDFCFE',
            borderTop: '1px solid #e5e5e5',
          }}
        >
          <Flex direction="column" gap="1">
            <Heading size="2" mb="1">
              Overview
            </Heading>
            <Text size="2">{summary}</Text>
          </Flex>
        </Box>
      </Box>
      <DiligenceQuestionAccordion
        diligenceResults={diligenceResults}
        selectedQuestion={selectedQuestion}
        setSelectedQuestion={setSelectedQuestion}
      />
      <MoreInfoPanel
        title="Answer sources"
        subtitle={`"${selectedQuestion?.question_text}"`}
        sources={selectedQuestion?.sources}
        onClose={() => {
          setSelectedQuestion(null);
        }}
        isOpen={!!selectedQuestion}
      />
    </Flex>
  );
};

export default DiligenceChecklist;
