import React, { useState, useContext, useMemo } from 'react';
import {
  Heading,
  Badge,
  Text,
  Flex,
  Table,
  TextField,
  Button,
  Box,
  IconButton,
  Spinner,
} from '@radix-ui/themes';
import {
  FileIcon,
  MagnifyingGlassIcon,
  UploadIcon,
  Cross1Icon,
  TriangleUpIcon,
} from '@radix-ui/react-icons';
import { useInterval } from './hooks/useInterval';
import { ProjectContext } from './ProjectContext';
import UploadFilesDialog from './UploadFilesDialog';
import { toTitleCase, prettifyDate } from './utils';
import './DocumentsOverview.css';

// TODO: remove this and replace with a more robust solution
const isUploading = (doc) => !doc.created_at;

// TODO: remove this and replace with a more robust solution
const isProcessing = (doc) => {
  const uploadedRecently =
    doc.created_at && new Date() - new Date(doc.created_at) < 2 * 60 * 1000; // uploaded <2 minutes ago
  const hasNoMetadata = !doc.file_type || doc.file_type === 'N/A';

  return uploadedRecently && hasNoMetadata;
};

const isInProgress = (doc) => isUploading(doc) || isProcessing(doc);

const StatusBadge = ({ children }) => (
  <Badge color="blue" radius="full" style={{ paddingLeft: 8, paddingRight: 8 }}>
    <Spinner size="1" />
    {children}
  </Badge>
);

const DocumentsOverview = ({ selectedDocument, setSelectedDocument }) => {
  const { projectDocuments, fetchDocuments, fetchDocumentMetadata } =
    useContext(ProjectContext);
  const [searchQuery, setSearchQuery] = useState('');

  const handleDocumentClick = async (document) => {
    if (selectedDocument && selectedDocument.id === document.id) {
      setSelectedDocument(null);
    } else {
      const response = await fetchDocumentMetadata(document.id);
      setSelectedDocument({ ...document, summary: response?.summary });
    }
  };

  // Sort by created_at in descending order, with uploading docs at the beginning
  const sortedDocuments = useMemo(() => {
    return [...projectDocuments].sort((a, b) => {
      if (isUploading(a)) return -1;
      if (isUploading(b)) return 1;
      return new Date(b.created_at) - new Date(a.created_at);
    });
  }, [projectDocuments]);

  const filteredDocuments = useMemo(() => {
    return sortedDocuments.filter((document) =>
      document.name.toLowerCase().includes(searchQuery.toLowerCase())
    );
  }, [sortedDocuments, searchQuery]);

  // Poll for document metadata every 5 seconds if there are documents processing
  useInterval(
    () => fetchDocuments(),
    projectDocuments.some(isProcessing) ? 5000 : null
  );

  if (sortedDocuments.length === 0) {
    return (
      <Flex
        direction="column"
        align="center"
        style={{ maxWidth: 560, margin: '8rem auto 0' }}
      >
        <Heading as="h2" size="5" mb="3">
          Upload your project files
        </Heading>
        <Text align="center" mb="4">
          Start by uploading your files – we'll automatically pull out important
          attributes. Aftwards, you can ask Lateral AI questions and more.
        </Text>
        <UploadFilesDialog
          trigger={
            <Button>
              <UploadIcon /> Start uploading
            </Button>
          }
        />
      </Flex>
    );
  }

  return (
    <Box pt="5">
      <Flex mb="5" align="center" justify="between">
        <TextField.Root
          placeholder="Search files…"
          value={searchQuery}
          onChange={(e) => setSearchQuery(e.target.value)}
        >
          <TextField.Slot>
            <MagnifyingGlassIcon height="16" width="16" />
          </TextField.Slot>
          <TextField.Slot
            style={{ visibility: searchQuery ? 'visible' : 'hidden' }}
          >
            <IconButton
              size="1"
              variant="ghost"
              mr="1"
              onClick={(e) => setSearchQuery('')}
            >
              <Cross1Icon height="10" width="10" />
            </IconButton>
          </TextField.Slot>
        </TextField.Root>
        <UploadFilesDialog
          trigger={
            <Button>
              <UploadIcon /> Upload files
            </Button>
          }
        />
      </Flex>
      <DocumentTable
        documents={filteredDocuments}
        selectedDocument={selectedDocument}
        onDocumentClick={handleDocumentClick}
      />
    </Box>
  );
};

const DocumentTable = ({ documents, selectedDocument, onDocumentClick }) => (
  <Table.Root variant="surface">
    <Table.Header>
      <Table.Row>
        <Table.ColumnHeaderCell width="40%">Name</Table.ColumnHeaderCell>
        <Table.ColumnHeaderCell width="18%">Type</Table.ColumnHeaderCell>
        <Table.ColumnHeaderCell width="7%">Version</Table.ColumnHeaderCell>
        <Table.ColumnHeaderCell width="9%">Written</Table.ColumnHeaderCell>
        <Table.ColumnHeaderCell width="9%">
          <Flex align="center" gap="1">
            Uploaded
            <TriangleUpIcon style={{ color: '#bbb' }} />
          </Flex>
        </Table.ColumnHeaderCell>
      </Table.Row>
    </Table.Header>
    <Table.Body>
      {documents.length > 0 ? (
        documents.map((document, index) => (
          <DocumentRow
            key={index}
            document={document}
            onDocumentClick={onDocumentClick}
            isSelected={selectedDocument?.id === document.id}
          />
        ))
      ) : (
        <Table.Row>
          <Table.Cell colSpan="5">
            <Flex justify="center" py="4">
              <Text color="gray">No results found</Text>
            </Flex>
          </Table.Cell>
        </Table.Row>
      )}
    </Table.Body>
  </Table.Root>
);

const DocumentRow = ({ document, onDocumentClick, isSelected }) => (
  <Table.Row
    className={`documents-overview-row ${isInProgress(document) ? 'in-progress' : ''} ${isSelected ? 'selected' : ''}`}
    onClick={!isInProgress(document) ? () => onDocumentClick(document) : null}
  >
    <Table.Cell maxWidth="144px">
      <Flex align="center" gap="2">
        <FileIcon
          width="20"
          height="20"
          opacity="0.4"
          style={{ flexShrink: 0 }}
        />
        <Text truncate={true} title={document.name}>
          {document.name}
        </Text>
        {isUploading(document) && <StatusBadge>Uploading...</StatusBadge>}
        {isProcessing(document) && <StatusBadge>Processing...</StatusBadge>}
      </Flex>
    </Table.Cell>
    <Table.Cell maxWidth="96px">
      {document.file_type && document.file_type !== 'N/A' && (
        <Flex>
          <Badge
            color="gray"
            variant="soft"
            size="1"
            style={{ maxWidth: '100%' }}
          >
            <Text truncate="true" title={toTitleCase(document.file_type)}>
              {toTitleCase(document.file_type)}
            </Text>
          </Badge>
        </Flex>
      )}
    </Table.Cell>
    <Table.Cell>
      {document.version && document.version !== 'N/A' && (
        <Badge color="gray" variant="soft" size="1">
          {document.version}
        </Badge>
      )}
    </Table.Cell>
    <Table.Cell>
      <Text wrap="nowrap">{prettifyDate(document.date_written)}</Text>
    </Table.Cell>
    <Table.Cell>
      <Text wrap="nowrap">{prettifyDate(document.created_at)}</Text>
    </Table.Cell>
  </Table.Row>
);

export default DocumentsOverview;
