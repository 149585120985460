import React, { useContext, useEffect, useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Button, Heading, Flex, Text, Table, Badge } from '@radix-ui/themes';
import { PlayIcon, TriangleUpIcon } from '@radix-ui/react-icons';
import { DiligenceContext } from './DiligenceContext';
import { ProjectContext } from './ProjectContext';
import { prettifyDate } from './utils';
import './DiligenceOverview.css';

const DiligenceChecklistsTable = ({ diligenceChecklists }) => {
  const navigate = useNavigate();

  const sortedResults = useMemo(() => {
    return [...diligenceChecklists].sort(
      (a, b) => new Date(b.created_at) - new Date(a.created_at)
    );
  }, [diligenceChecklists]);

  return (
    <Table.Root variant="surface">
      <Table.Header>
        <Table.Row>
          <Table.ColumnHeaderCell width="50%">Project</Table.ColumnHeaderCell>
          <Table.ColumnHeaderCell>Passed</Table.ColumnHeaderCell>
          <Table.ColumnHeaderCell>Uncertain</Table.ColumnHeaderCell>
          <Table.ColumnHeaderCell>Failed</Table.ColumnHeaderCell>
          <Table.ColumnHeaderCell>
            <Flex align="center" gap="1">
              Ran
              <TriangleUpIcon style={{ color: '#bbb' }} />
            </Flex>
          </Table.ColumnHeaderCell>
        </Table.Row>
      </Table.Header>

      <Table.Body>
        {sortedResults.map(({ createdAt, counts, project }) => (
          <Table.Row
            key={project.name}
            className="diligence-overview-row"
            onClick={() => navigate(`projects/${project.id}`)}
          >
            <Table.Cell maxWidth="96px">
              <Badge
                color="gray"
                variant="soft"
                size="1"
                style={{ maxWidth: '100%' }}
              >
                <Text truncate={true} title={project.name}>
                  {project.name}
                </Text>
              </Badge>
            </Table.Cell>
            <Table.Cell>{counts.passed}</Table.Cell>
            <Table.Cell>{counts.uncertain}</Table.Cell>
            <Table.Cell>{counts.failed}</Table.Cell>
            <Table.Cell>
              <Text wrap="nowrap">{prettifyDate(createdAt)}</Text>
            </Table.Cell>
          </Table.Row>
        ))}
      </Table.Body>
    </Table.Root>
  );
};

const countResults = (checklist) =>
  checklist.reduce(
    (acc, result) => {
      acc[result.evaluation_status]++;
      return acc;
    },
    { passed: 0, uncertain: 0, failed: 0 }
  );

const DiligenceOverview = () => {
  const { getDiligenceResults } = useContext(DiligenceContext);
  const { selectedProjects } = useContext(ProjectContext);
  const [diligenceChecklists, setDiligenceChecklists] = useState([]);

  useEffect(() => {
    const fetchDiligenceChecklists = async () => {
      const results = await Promise.all(
        selectedProjects.map((project) => getDiligenceResults(project.id))
      );

      const checklists = results
        .map((result) => result.diligence_checklist)
        .map((checklist, index) => {
          if (checklist.length === 0) return null;

          return {
            project: selectedProjects[index],
            createdAt: checklist[0].created_at,
            counts: countResults(checklist),
          };
        })
        .filter(Boolean);

      setDiligenceChecklists(checklists);
    };

    fetchDiligenceChecklists();
  }, [selectedProjects, getDiligenceResults]);

  if (diligenceChecklists.length === 0) {
    return (
      <Flex
        direction="column"
        align="center"
        style={{ maxWidth: 560, margin: '8rem auto 0' }}
      >
        <Heading as="h2" size="5" mb="3">
          No diligence checklists
        </Heading>
        <Text align="center" mb="4">
          Fast-track your due diligence. Run diligence checks and we'll evaluate
          the most pressing criteria for you. Select a project on the left to
          get started.
        </Text>
      </Flex>
    );
  }

  return (
    <Flex direction="column">
      <Flex mb="5" justify="between" align="center">
        <Heading size="7">Diligence checklists</Heading>
      </Flex>
      <DiligenceChecklistsTable diligenceChecklists={diligenceChecklists} />
    </Flex>
  );
};

export default DiligenceOverview;
