import React, { createContext, useState, useEffect, useCallback } from 'react';
import { usePreventRefreshOnUpload } from './hooks/usePreventRefreshOnUpload';
import { BASE_URL } from './App';
import { getCurrentDate } from './utils';

export const ProjectContext = createContext();

const getFromLocalStorage = (key, defaultValue) => () => {
  const storedValue = localStorage.getItem(key);
  return storedValue ? JSON.parse(storedValue) : defaultValue;
};

export const ProjectProvider = ({ children }) => {
  const { setIsUploading } = usePreventRefreshOnUpload();
  const [projects, setProjects] = useState([]);
  const [selectedProjects, setSelectedProjects] = useState(
    getFromLocalStorage('selectedProjects', [])
  );
  const [activeProject, setActiveProject] = useState(
    getFromLocalStorage('activeProject', null)
  );
  const [projectDocuments, setProjectDocuments] = useState([]);
  const [projectAttributes, setProjectAttributes] = useState([]);

  const createProject = useCallback(
    async (projectDetails) => {
      try {
        const response = await fetch(`${BASE_URL}/api/v1/projects`, {
          method: 'POST',
          credentials: 'include',
          headers: { 'Content-Type': 'application/json' },
          body: JSON.stringify(projectDetails),
        });
        const newProjectData = await response.json();
        if (response.ok) {
          const newProject = {
            id: newProjectData.project_id,
            name: projectDetails.project_name,
            type: projectDetails.project_type,
            created_at: getCurrentDate(),
          };

          setProjects([...projects, newProject]);
          setSelectedProjects((prevSelectedProjects) => [
            ...prevSelectedProjects,
            newProject,
          ]);

          return newProject;
        } else {
          console.error('Failed to create project:', newProjectData.message);
        }
      } catch (error) {
        console.error('Error creating new project:', error);
      }
    },
    [projects]
  );

  const fetchProjects = useCallback(async () => {
    try {
      const response = await fetch(`${BASE_URL}/api/v1/projects`, {
        method: 'GET',
        credentials: 'include',
        headers: { 'Content-Type': 'application/json' },
      });
      const data = await response.json();

      if (response.ok) {
        setProjects(data.projects);
        setSelectedProjects((prevSelectedProjects) =>
          prevSelectedProjects.filter((sp) =>
            data.projects.some((p) => p.id === sp.id)
          )
        );
      }
    } catch (error) {
      console.error('Error fetching projects:', error);
    }
  }, []);

  const uploadDocuments = useCallback(
    async (files) => {
      try {
        if (files.length > 0) {
          setIsUploading(true);

          const uploadPromises = [...files].map(async (file) => {
            const formData = new FormData();
            formData.append('files', file);
            formData.append('project_id', activeProject.id);

            setProjectDocuments((prevDocs) => [
              ...prevDocs,
              { name: file.name },
            ]);

            const response = await fetch(`${BASE_URL}/api/v1/files`, {
              method: 'POST',
              credentials: 'include',
              body: formData,
            });

            const newDocData = await response.json();
            if (response.ok) {
              const newDoc = newDocData.files[0];
              setProjectDocuments((prevDocs) =>
                prevDocs.map((doc) => (doc.name === file.name ? newDoc : doc))
              );

              return newDoc;
            } else {
              console.error('Failed to upload document:', newDocData.message);
            }
          });

          await Promise.all(uploadPromises);
          setIsUploading(false);
        }
      } catch (error) {
        console.error('Error adding documents:', error);
        setIsUploading(false);
      }
    },
    [activeProject, setIsUploading]
  );

  const fetchDocuments = useCallback(async () => {
    try {
      const response = await fetch(
        `${BASE_URL}/api/v1/projects/${activeProject.id}/files`,
        {
          method: 'GET',
          credentials: 'include',
          headers: {
            'Content-Type': 'application/json',
          },
        }
      );

      const data = await response.json();
      setProjectDocuments(data.files);
    } catch (error) {
      console.error('Error fetching documents:', error);
    }
  }, [activeProject]);

  const fetchDocumentMetadata = useCallback(
    async (documentId) => {
      try {
        const response = await fetch(
          `${BASE_URL}/api/v1/projects/${activeProject.id}/files/${documentId}/metadata`,
          {
            method: 'GET',
            credentials: 'include',
            headers: {
              'Content-Type': 'application/json',
            },
          }
        );

        const data = await response.json();
        return data;
      } catch (error) {
        console.error('Error fetching document metadata:', error);
      }
    },
    [activeProject]
  );

  const fetchAttributes = useCallback(async () => {
    try {
      const response = await fetch(
        `${BASE_URL}/api/v1/projects/${activeProject.id}/attributes`,
        {
          method: 'GET',
          credentials: 'include',
          headers: {
            'Content-Type': 'application/json',
          },
        }
      );

      const data = await response.json();
      setProjectAttributes(data.attributes);
    } catch (error) {
      console.error('Error fetching attributes:', error);
    }
  }, [activeProject]);

  const fetchAttributeSources = useCallback(
    async (attributeId) => {
      try {
        const response = await fetch(
          `${BASE_URL}/api/v1/projects/${activeProject.id}/attributes/${attributeId}/sources`,
          {
            method: 'GET',
            credentials: 'include',
            headers: {
              'Content-Type': 'application/json',
            },
          }
        );

        const data = await response.json();
        return data;
      } catch (error) {
        console.error('Error fetching attribute sources:', error);
      }
    },
    [activeProject]
  );

  // Effect for fetching projects upon component mount
  useEffect(() => {
    fetchProjects();
  }, [fetchProjects]);

  // Effect for fetching project-specific data when active project changes
  useEffect(() => {
    if (activeProject && activeProject.id) {
      fetchDocuments();
      fetchAttributes();
      localStorage.setItem('activeProject', JSON.stringify(activeProject));
    } else {
      // If there is no active project, clear related state and localStorage
      setProjectAttributes([]);
      setProjectDocuments([]);
      localStorage.removeItem('activeProject');
    }
  }, [activeProject, fetchDocuments, fetchAttributes]);

  useEffect(() => {
    localStorage.setItem('selectedProjects', JSON.stringify(selectedProjects));
  }, [selectedProjects]);

  return (
    <ProjectContext.Provider
      value={{
        projects,
        fetchProjects,
        selectedProjects,
        setSelectedProjects,
        activeProject,
        setActiveProject,
        createProject,
        projectDocuments,
        uploadDocuments,
        fetchDocuments,
        fetchDocumentMetadata,
        projectAttributes,
        fetchAttributeSources,
      }}
    >
      {children}
    </ProjectContext.Provider>
  );
};
