import React, { createContext } from 'react';
import { BASE_URL } from './App';

export const FAQContext = createContext();

export const FAQProvider = ({ children }) => {
  const createProjectQuestion = async (projectId, question) => {
    const response = await fetch(
      `${BASE_URL}/api/v1/projects/${projectId}/questions`,
      {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ question_text: question }),
        credentials: 'include',
      }
    );
    const data = await response.json();

    return data;
  };

  const streamProjectQuestionAnswer = async (projectId, questionId) => {
    const response = await fetch(
      `${BASE_URL}/api/v1/projects/${projectId}/questions/${questionId}/stream`,
      {
        method: 'POST',
        credentials: 'include',
      }
    );
    return response;
  };

  const getProjectQuestion = async (projectId, questionId) => {
    const response = await fetch(
      `${BASE_URL}/api/v1/projects/${projectId}/questions/${questionId}`,
      {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
        },
        credentials: 'include',
      }
    );
    const data = await response.json();
    return data;
  };

  const askFileQuestion = async (projectId, fileId, question) => {
    const response = await fetch(
      `${BASE_URL}/api/v1/projects/${projectId}/questions/files/${fileId}`,
      {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ question_text: question }),
        credentials: 'include',
      }
    );
    const data = await response.json();

    return data;
  };

  const getFAQs = async (projectId) => {
    const response = await fetch(
      `${BASE_URL}/api/v1/projects/${projectId}/faqs`,
      {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
        },
        credentials: 'include',
      }
    );
    const data = await response.json();

    return data;
  };

  const publishToFAQ = async (projectId, faqId, questionId) => {
    const response = await fetch(
      `${BASE_URL}/api/v1/projects/${projectId}/faqs/${faqId}/faq-item`,
      {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ question_id: questionId }),
        credentials: 'include',
      }
    );
    const data = await response.json();

    return data;
  };

  return (
    <FAQContext.Provider
      value={{
        createProjectQuestion,
        streamProjectQuestionAnswer,
        getProjectQuestion,
        getFAQs,
        publishToFAQ,
      }}
    >
      {children}
    </FAQContext.Provider>
  );
};
