import React, { useCallback, useContext, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import {
  Button,
  DropdownMenu,
  Text,
  Flex,
  Heading,
  Separator,
  IconButton,
} from '@radix-ui/themes';
import { ArrowRightIcon, DotsVerticalIcon } from '@radix-ui/react-icons';
import { FAQContext } from './FAQContext';
import { ProjectContext } from './ProjectContext';
import AskLateralAI from './AskLateralAI';
import MoreInfoPanel from './MoreInfoPanel';
import { prettifyDate } from './utils';
import { MORE_INFO_PANEL_WIDTH } from './utils/constants';

const QuestionDropdown = ({ onViewSourcesClick }) => (
  <DropdownMenu.Root>
    <DropdownMenu.Trigger>
      <IconButton variant="ghost" color="gray" highContrast={true}>
        <DotsVerticalIcon />
      </IconButton>
    </DropdownMenu.Trigger>
    <DropdownMenu.Content variant="soft">
      <DropdownMenu.Item onClick={onViewSourcesClick}>
        View sources
      </DropdownMenu.Item>
      <DropdownMenu.Item>Edit answer</DropdownMenu.Item>
      <DropdownMenu.Item color="red">Delete</DropdownMenu.Item>
    </DropdownMenu.Content>
  </DropdownMenu.Root>
);

const QuestionCard = ({ question, handlers }) => (
  <Flex
    direction="column"
    gap="4"
    p="5"
    style={{
      background: 'white',
      border: '1px solid #e5e5e5',
      borderRadius: 12,
    }}
  >
    <Flex justify="between" width="100%">
      <Flex direction="column">
        <Text size="2" weight="bold">
          {question.question}
        </Text>
        <Text size="1" color="gray" style={{ opacity: 0.7, fontWeight: 600 }}>
          {prettifyDate(question.created_at)}
        </Text>
      </Flex>
      {/* <QuestionDropdown {...handlers} /> */}
    </Flex>
    <Text size="2" style={{ whiteSpace: 'pre-wrap' }}>
      {question.answer}
    </Text>
  </Flex>
);

const FAQDetail = () => {
  const { getFAQs } = useContext(FAQContext);
  const { activeProject, projectDocuments, fetchDocuments } =
    useContext(ProjectContext);
  const [faq, setFaq] = useState(null);
  const [selectedQuestion, setSelectedQuestion] = useState(null);
  const navigate = useNavigate();

  const fetchFAQ = useCallback(
    async () => getFAQs(activeProject.id).then(({ faqs }) => setFaq(faqs[0])),
    [activeProject, getFAQs]
  );

  useEffect(() => {
    activeProject && fetchFAQ();
    activeProject && fetchDocuments(); // TODO: only call if faq.faq_items.length === 0
  }, [activeProject, fetchFAQ, fetchDocuments]);

  const handleViewSourcesClick = (question) => {
    // TODO: fetch sources
    setSelectedQuestion(question);
  };

  if (projectDocuments.length === 0) {
    return (
      <Flex
        direction="column"
        align="center"
        style={{ maxWidth: 380, margin: '8rem auto 0' }}
      >
        <Heading as="h2" size="5" mb="3">
          Project files required
        </Heading>
        <Text align="center" mb="4">
          Lateral AI uses the project files to generate answers to your
          questions. Upload some to get started.
        </Text>
        <Button
          disabled={!activeProject}
          onClick={() => navigate(`/overview/projects/${activeProject.id}`)}
        >
          Upload files
          <ArrowRightIcon />
        </Button>
      </Flex>
    );
  }

  return (
    <Flex
      direction="column"
      style={{
        paddingRight: selectedQuestion ? MORE_INFO_PANEL_WIDTH : 0,
        transition: 'padding-right 0.1s ease-in-out',
      }}
    >
      <Flex
        direction="column"
        style={{
          width: '100%',
          maxWidth: 720,
          marginLeft: 'auto',
          marginRight: 'auto',
        }}
      >
        <Flex justify="between" mb="5">
          <Heading size="7">Project FAQ</Heading>
        </Flex>

        <Separator size="4" mb="5" />

        <AskLateralAI
          key={faq?.id}
          faq={faq}
          onViewSourcesClick={setSelectedQuestion}
          isViewingSources={selectedQuestion?.isUnpublished}
          onPublish={() => fetchFAQ()}
        />

        <Flex direction="column" gap="4">
          {faq && faq.faq_items.length !== 0 ? (
            faq.faq_items.map((q) => (
              <QuestionCard
                key={q.id}
                question={q}
                handlers={
                  {
                    // onViewSourcesClick: () => handleViewSourcesClick(q),
                  }
                }
              />
            ))
          ) : (
            <Flex direction="column" align="center">
              <Text size="2" color="gray">
                {/* No questions yet */}
              </Text>
            </Flex>
          )}
        </Flex>

        <MoreInfoPanel
          title="Answer sources"
          subtitle={`"${selectedQuestion?.question}"`}
          sources={selectedQuestion?.sources}
          onClose={() => {
            setSelectedQuestion(null);
          }}
          isOpen={!!selectedQuestion}
        />
      </Flex>
    </Flex>
  );
};

export default FAQDetail;
