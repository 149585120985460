import React from 'react';
import { Text, Heading, Flex, Card, Button } from '@radix-ui/themes';
import PaywallBG from './assets/attributes-paywall.png';
import './AttributesPaywall.css';

const AttributesPaywall = () => {
  return (
    <Flex position="relative" className="attributes-paywall">
      <img
        src={PaywallBG}
        alt="Attributes paywall"
        className="paywall-bg-image"
        draggable="false"
      />
      <Flex className="paywall-content">
        <Card
          size="3"
          style={{
            width: 330,
          }}
        >
          <Heading size="5" weight="bold" width="100%" align="center">
            Enterprise plan required
          </Heading>
          <Text as="p" size="2" mt="3" mb="5" width="100%" align="center">
            Lateral will automatically extract financial model inputs from your
            files.
          </Text>
          <a href="mailto:info@lateralenergy.com">
            <Button size="2" style={{ width: '100%' }}>
              Request more info
            </Button>
          </a>
        </Card>
      </Flex>
    </Flex>
  );
};

export default AttributesPaywall;
