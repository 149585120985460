import { format, formatDistanceToNowStrict, parse } from 'date-fns';

export const getProjectIconName = (projectType) =>
  `${toTitleCase(projectType).replace(/ /g, '')}Icon`;

export const toTitleCase = (str = '') => {
  return str
    .split('_')
    .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
    .join(' ');
};

export const getCurrentDate = () => new Date().toUTCString();

export const prettifyDate = (dateString) => {
  if (!dateString) return '';

  try {
    let timeAgo = formatDistanceToNowStrict(new Date(dateString), {
      addSuffix: true,
      width: 'short',
    });

    const abbreviations = {
      minute: 'min',
      minutes: 'mins',
      second: 'sec',
      seconds: 'secs',
    };

    return timeAgo.replace(
      /(minute|second)s?/g,
      (match) => abbreviations[match]
    );
  } catch (error) {
    // console.error('Error parsing date:', error);
    return '';
  }
};

export const slugify = (text = '') => {
  return text
    .toLowerCase()
    .replace(/[^a-z0-9]+/g, '-')
    .replace(/(^-|-$)/g, '');
};

export const pluralize = (word, count) => {
  return count === 1 ? word : `${word}s`;
};

export const extractProjectIDFromURL = (pathname) => {
  const regex = /\/projects\/(\d+)/;
  const match = pathname.match(regex);
  return match ? match[1] : null;
};

export const isValidUrl = (url) => {
  try {
    new URL(url);
    return true;
  } catch {
    return false;
  }
};
