import React, { useContext, useState } from 'react';
import Markdown from 'react-markdown';
import {
  Box,
  Button,
  Flex,
  Heading,
  Reset,
  Link,
  Tabs,
  Text,
} from '@radix-ui/themes';
import { ExternalLinkIcon } from '@radix-ui/react-icons';
import { ProjectContext } from './ProjectContext';
import DocumentsOverview from './DocumentsOverview';
import MoreInfoPanel from './MoreInfoPanel';
import Icon from './Icon';
import { getProjectIconName } from './utils';
import { MORE_INFO_PANEL_WIDTH } from './utils/constants';

const ProjectDetail = () => {
  const { activeProject: project } = useContext(ProjectContext);
  const [selectedDocument, setSelectedDocument] = useState(null);

  if (!project) {
    return null;
  }

  return (
    <Flex
      direction="column"
      style={{
        marginRight: selectedDocument ? MORE_INFO_PANEL_WIDTH : 0,
        transition: 'margin-right 0.1s ease-in-out',
      }}
    >
      <Flex align="center" mb="5">
        <Icon
          name={getProjectIconName(project.type)}
          width="32"
          height="32"
          opacity="0.35"
          style={{ marginRight: 12, marginTop: 1 }}
        />
        <Heading size="7">{project.name}</Heading>
      </Flex>
      <Tabs.Root defaultValue="files">
        <Tabs.List>
          <Tabs.Trigger value="files">Files</Tabs.Trigger>
        </Tabs.List>

        <Box pb="2">
          <Tabs.Content value="files">
            <DocumentsOverview
              selectedDocument={selectedDocument}
              setSelectedDocument={setSelectedDocument}
            />
          </Tabs.Content>
        </Box>
      </Tabs.Root>
      <MoreInfoPanel
        title="File summary"
        subtitle={`${selectedDocument?.name}`}
        onClose={() => {
          setSelectedDocument(null);
        }}
        isOpen={!!selectedDocument}
      >
        <Flex p="4" direction="column" className="markdown-content">
          {selectedDocument?.file_link && (
            <Link
              href={selectedDocument?.file_link}
              target="_blank"
              rel="noopener noreferrer"
              mb="4"
              style={{ maxWidth: 88 }}
            >
              <Button size="1" variant="outline">
                Open file
                <ExternalLinkIcon />
              </Button>
            </Link>
          )}
          <Text size="2">
            <Markdown>{selectedDocument?.summary}</Markdown>
          </Text>
        </Flex>
      </MoreInfoPanel>
    </Flex>
  );
};

export default ProjectDetail;
